const contactData = [
  {
    id: "contact-2",
    title: "LinkedIn",
    url: "https://www.linkedin.com/in/kendebacker/",
    iconColor: "#0072b1 ",
  },
  {
    id: "contact-4",
    title: "Resume",
    url: "https://drive.google.com/file/d/1IrZb6-4oRMqIFvbSpVrmpP0itQB2whmO",
    iconColor: "#F8B32F",
  },
  {
    id: "contact-1",
    title: "Github",
    url: "https://github.com/kendebacker",
    iconColor: "black",
  },
  {
    id: "contact-3",
    title: "Email",
    url: "mailto:kcdebacker@gmail.com",
    iconColor: "#DB4437",
  },
];

export default contactData;
