import Button from "@mui/material/Button";

export default function ShakingButton({ startIcon, text, url }) {
  const rotateAngle = 10;

  return (
    <Button
      variant="text"
      startIcon={startIcon}
      sx={{
        marginRight: "8px",
        "&:hover": {
          md: {
            "& svg": {
              "@keyframes shake": {
                "0%": {
                  transform: `rotate(0deg)`,
                },
                "16%": {
                  transform: `rotate(-${rotateAngle}deg)`,
                },
                "32%": {
                  transform: `rotate(0deg)`,
                },
                "48%": {
                  transform: `rotate(${rotateAngle}deg)`,
                },
                "64%": {
                  transform: `rotate(0deg)`,
                },
                "80%": {
                  transform: `rotate(-${rotateAngle}deg)`,
                },
                "100%": {
                  transform: `rotate(0deg)`,
                },
              },
              animation: `shake ease-in-out .5s`,
            },
          },
        },
      }}
      onClick={() => {
        window.location.href = url;
      }}
      size="medium"
    >
      {text}
    </Button>
  );
}
