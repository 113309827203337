const introData = {
  title: "Hi there!",
  images: [
    { src: "/images/introImage.png", alt: "My girlfriend and I on Mt. Rainer" },
  ],
  paragraphs: [
    {
      id: "para-1",
      content:
        "Thanks for stopping by my site! I'm Ken, a software developer currently completing my masters at the University of Michigan.",
    },
    {
      id: "para-2",
      content:
        "A little bit about me. Pre-adulthood I lived in Chicago, Boston, and Ann Arbor, and got a B.S. in chemistry from the University of Chicago. After that, I made a career change, taught myself CS fundamentals, and subsequently found employment. I worked for a few years, found I really enjoyed building applications, and decided to go back to school to gain more experince in modern development.",
    },
    {
      id: "para-3",
      content:
        "I started my masters in Fall of 2022 and have really enjoyed the experience. I've gotten exposure to lots of development concepts and techniques and have practiced applying what I've learned in assignments and projects. Another major bonus of going back to school was the ability to gain further experience through internships. I'm currently completing a frontend engineering internship at ATPCO (Airline Tarrif Publishing Company), and have enjoyed the experience immensly.",
    },
    {
      id: "para-4",
      content:
        "When not sitting in front of a computer, I enjoy going on adventures with my girlfriend, playing ultimate frisbee (pickup and club), and fishing, a passion I've had since fourth grade. Feel free to browse around the site and view my resume, LinkedIn, and GitHub to see other things I've been up to.",
    },
  ],
};

export default introData;
