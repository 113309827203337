import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardHeader } from "@mui/material";
import { getIcon } from "../data/iconMap";
import ShakingButton from "./ShakingButton";

export default function PublicationDisplay({ publication, index }) {
  return (
    <Card
      sx={{
        width: { xs: "95%", md: "75%" },
        boxShadow: 2,
      }}
    >
      <CardHeader
        variant="h2"
        title={publication.title}
        subheader={publication.journal}
      ></CardHeader>
      <CardMedia
        component="img"
        sx={{ objectFit: "contain", maxWidth: "1000px" }}
        image={publication.image.src}
        alt={publication.image.alt}
        loading={index === 0 ? "eager" : "lazy"}
      />
      <CardContent>
        <Typography
          variant="body1"
          component="div"
          sx={{ "&:last-child": { marginBlockEnd: 0 } }}
        >
          {publication.authors.split(",").map((el, ind) => {
            const text = `${el}${
              ind === publication.authors.split(",").length - 1 ? "" : ", "
            }`;
            return ind !== publication.boldIndex ? (
              <span key={`${el}${ind}`}>{text}</span>
            ) : (
              <b key={`${el}${ind}`}>{text}</b>
            );
          })}
        </Typography>
      </CardContent>
      <CardActions>
        <ShakingButton
          url={publication.link}
          startIcon={getIcon("publications", "primary", 35, null)}
          text={"View"}
          keyVal={publication.id}
        />
      </CardActions>
    </Card>
  );
}
