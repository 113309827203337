import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFishFins } from "@fortawesome/free-solid-svg-icons";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SwimmingFish() {
  const animationDuration = useMediaQuery("(min-width:900px)") ? 6 : 4;
  const fishVerticalChange = 7;
  const fishRotationChange = 5;
  const fishTravelDistance = "68vw";

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}, .${tooltipClasses.arrow}:before`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const [showSpeech, setShowSpeech] = useState(false);
  const [toolTipContent, setToolTipContent] = useState("Whoops!");
  const [animationRunning, setAnimationRunning] = useState(false);

  const runFish = () => {
    if (!animationRunning) {
      setAnimationRunning(true);
      setToolTipContent("Whoops!");
      setTimeout(() => {
        setShowSpeech(true);
        setTimeout(() => {
          setToolTipContent("😅");
          setTimeout(() => {
            setShowSpeech(false);
            setAnimationRunning(false);
          }, 2000);
        }, 2000);
      }, animationDuration * 1000 + 500);
    }
  };

  return (
    <Box
      sx={
        !animationRunning
          ? { cursor: "pointer" }
          : {
              "@keyframes width-increase": {
                "0%": {
                  marginTop: "0vh",
                  marginLeft: "0vw",
                },
                "2%": {},
                "23%": {},
                "25%": {
                  marginTop: `-${fishVerticalChange / 2}vh`,
                },
                "48%": {},
                "50%": {
                  marginTop: "0vh",
                  marginLeft: `${fishTravelDistance}`,
                  transform: "rotateY(0deg)",
                },
                "53%": {
                  transform: "rotateY(180deg)",
                },
                "60%": {},
                "75%": {
                  marginTop: `${fishVerticalChange + 7}vh`,
                },
                "95%": {
                  transform: "rotateY(180deg)",
                },
                "100%": {
                  transform: "rotateY(0deg)",
                  marginLeft: "0vw",
                },
              },
              animation: `width-increase ease-in-out ${animationDuration}s`,
            }
      }
    >
      <Box
        sx={
          !animationRunning
            ? {}
            : {
                "@keyframes width-increase-inner": {
                  "0%": {
                    transform: `rotate(-${fishRotationChange}deg)`,
                  },
                  "2%": {
                    transform: `rotate(-${fishRotationChange}deg)`,
                  },
                  "20%": {
                    transform: `rotate(-${fishRotationChange}deg)`,
                  },
                  "21%": {
                    transform: `rotate(${fishRotationChange}deg)`,
                  },
                  "48%": {
                    transform: `rotate(${fishRotationChange}deg)`,
                  },
                  "50%": {
                    transform: "rotate(0deg)",
                  },
                  "55%": {},
                  "60%": {
                    transform: `rotate(${fishRotationChange}deg)`,
                  },
                  "70%": {
                    transform: `rotate(${fishRotationChange}deg)`,
                  },
                  "70.5%": {
                    transform: `rotate(-${fishRotationChange}deg)`,
                  },
                  "95%": {},
                  "100%": {},
                },
                animation: `width-increase-inner ease-in-out ${animationDuration}s`,
              }
        }
      >
        <LightTooltip
          open={showSpeech}
          title={toolTipContent}
          placement="right"
          arrow
        >
          <FontAwesomeIcon
            onClick={() => runFish()}
            icon={faFishFins}
            size="lg"
          />
        </LightTooltip>
      </Box>
    </Box>
  );
}
