const projectData = [
  {
    id: "project-4",
    title: "RefugeeONE Job Board",
    date: "Spring 2023",
    image: {
      src: "/images/project-4.png",
      srcMobile: "/images/project-4-mobile.png",
      alt: "RefugeeONE diagram",
    },
    descriptions: [
      {
        id: "desc-1",
        content: [
          [
            "For a school course five other students and I were tasked with designing and building a job board for ",
          ],
          ["RefugeeONE", "https://www.refugeeone.org/"],
          [
            ", a refugee resettlement agency in Chicago, to aid refugees when they are searching for work. After completing four sprint cycles, we ended up creating a fully customized job board for RefugeeONE using the Daisy UI component library, Tailwind CSS, React, and Google Firebase. The job board features three types of profiles: admins, employers and users, each of which has different access rights and abilities. The full scope of application and the way each of these user types fit into it is quite extensive, and can be viewed ",
          ],
          ["on our portfolio site", "https://refugeeone699.github.io/"],
          [
            ". Some highlights of the finished application include the use of email authorization for user onboarding, the ability for users to filter jobs by different parameters (notably distance), and the entire user UI being completely mobile compatible, as often mobile phones are the only means refugees have of accessing the internet.",
          ],
        ],
      },
      {
        id: "desc-2",
        content: [
          [
            "This project allowed me to further my experience with using React and Firebase, and gave me exposure to Tailwind CSS, which I found I quite liked. Moreover, I gained experience developing a complex application with a team, and dealing with topics like git best practices, code review, code conflicts, and working with other people’s code and Figma designs. I also got to go through sprint cycles with a real-world client, and the iterations and revisions that came with it.",
          ],
        ],
      },
    ],
    linksIcon: [
      {
        id: "linkIcon-2",
        title: "youtube link",
        url: "https://www.youtube.com/watch?v=YzZrsk8REAc",
        iconName: "youtube",
        iconColor: "#FF0000",
      },
      {
        id: "linkIcon-1",
        title: "github link",
        url: "https://github.com/RefugeeOne699/refugee-one",
        iconName: "github",
        iconColor: "black",
      },
    ],
    linksNoIcon: [
      {
        id: "link-1",
        title: "View Project",
        url: "https://refugeeone699.github.io/",
        iconName: "Portfolio Site",
        iconColor: "black",
      },
    ],
  },
  {
    id: "project-3",
    title: "Online Pokédex",
    date: "Spring 2023",
    image: {
      src: "/images/project-3.png",
      srcMobile: "/images/project-3-mobile.png",
      alt: "Main page of Pokédex",
    },
    descriptions: [
      {
        id: "desc-1",
        content: [
          [
            "After accepting my ATPCO internship I found that I would be primarily working on an Angular component library. Having no prior experience with Angular, I built this Pokédex as a fun way to familiarize myself with the basics. The app is built using Angular and the MUI Angular component library, and fetches data from ",
          ],
          ["a Pokémon API (PokéAPI)", "https://pokeapi.co/"],
          [
            ". Pokémon can be filtered by generation and element type, and ordered by ID, Generation, Weight, HP, Element, and Name. Selecting a certain Pokémon will show in-depth details such as abilities, stats, and moves. Additionally, Pokémon can be viewed as classic drawn images or the more modern digitally rendered images.",
          ],
        ],
      },
      {
        id: "desc-2",
        content: [
          [
            "Coming from a React background, this project familiarized me with basic Angular concepts like component structure, directives, DOM interaction, etc. This was also my first time using an MUI library. I enjoyed creating this small application and believe it allowed me to contribute at my internship faster.",
          ],
        ],
      },
    ],
    linksIcon: [
      {
        id: "linkIcon-1",
        title: "github link",
        url: "https://github.com/kendebacker/pokedex",
        iconName: "github",
        iconColor: "black",
      },
    ],
    linksNoIcon: [
      {
        id: "link-1",
        title: "View Project",
        url: "https://kens-pokedex.com/home",
        iconName: "youtube",
        iconColor: "black",
      },
    ],
  },
  {
    id: "project-2",
    title: "FoodFriends Social Media Application",
    date: "Fall 2022",
    image: {
      src: "/images/project-2.png",
      srcMobile: "/images/project-2-mobile.png",
      alt: "FoodFriends Logo",
    },
    descriptions: [
      {
        id: "desc-1",
        content: [
          [
            "This was my final project for a mobile development class. I chose to create a social media platform for sharing food using React Native and Firebase. Once logged in, users can go to their feed to create posts or view, like, and comment on friends' posts. Viewing the details of a post lets the user access more information such as the rating, description, recipe, and location (openable in maps) of food posted about. Posts can also be saved to a user's profile and viewed later. Outside of the general CRUD features, FoodFriends also utalizes the user's camera, implements basic login authorization, and features a user search for adding friends.",
          ],
        ],
      },
      {
        id: "desc-2",
        content: [
          [
            "This project let me further hone skills that I have learned over the semester. I also got a lot of experience using Redux to manage the application state. While there was a bit of a learning curve for this in the beginning, it definitely helped me to streamline my state management and kept it from becomming cluttered as the application was flushed out. Retrospectively, given the time constraints I would have used an existing component library like MUI instead of making everything myself. I think this would have eleviated some of the deadline issues I ran into and provided a more polished look for the final application.",
          ],
        ],
      },
    ],
    linksIcon: [
      {
        id: "linkIcon-2",
        title: "youtube link",
        url: "https://www.youtube.com/watch?v=CFA_TfF0b5k",
        iconName: "youtube",
        iconColor: "#FF0000",
      },
      {
        id: "linkIcon-1",
        title: "github link",
        url: "https://github.com/kendebacker/foodfriends",
        iconName: "github",
        iconColor: "black",
      },
    ],
    linksNoIcon: [],
  },
  {
    id: "project-1",
    title: "Online Chess Game",
    date: "Spring 2022",
    image: {
      src: "/images/project-1.png",
      srcMobile: "/images/project-1-mobile.png",
      alt: "Main Page of Online Chess Game",
    },
    descriptions: [
      {
        id: "desc-1",
        content: [
          [
            "When I was first learning React I completed the React tic-tac-toe tutorial. I thought it would be fun/good experience to complete a more complex application. The completed game uses a stack of React, Express, Node, and Socket.IO, using websockets to allow two players to play eachother from different devices. Some highlights of the application include it's ability to tell if a player is in check/checkmate and a review feature which allows the players to both view their previous moves and resume the game at a previous point in time.",
          ],
        ],
      },
      {
        id: "desc-2",
        content: [
          [
            "Having gotten more experience with React development both through school coursework as well as projects, there are definitely a lot of things about this project that I would do differently if I were to do it again, both from a technical side as well as the design standpoint. That being said, I am still proud of making this application and think it was a good first step for me to get my feet we in modern JavaScript development.",
          ],
        ],
      },
      {
        id: "desc-3",
        content: [
          [
            "Instructions to play: To play, one person must first make a room to hold their game by clicking the 'Make Room' option, choosing a room number, and creating the room. Once the room is created, the second player can join the room from a different device by selecting the 'Join Room' option and giving the room's number when prompted. To move pieces around, click the piece you would like to move, and then click the location you would like to move it to, only legal moves are allowed! ",
          ],
        ],
      },
    ],
    linksIcon: [],
    linksNoIcon: [
      {
        id: "link-1",
        title: "View Project",
        url: "https://www.kens-chess.com/",
        iconName: "youtube",
        iconColor: "black",
      },
    ],
  },
];

export default projectData;
