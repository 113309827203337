import * as React from "react";
import Stack from "@mui/material/Stack";
import ProjectDisplay from "../components/ProjectDisplay";
import projectData from "../data/projectData";
import { Typography } from "@mui/material";

const Projects = () => {
  return (
    <Stack
      sx={{
        marginTop: { xs: "2rem", md: "3rem" },
        marginBottom: { xs: "2rem", md: "3rem" },
      }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
              <Typography variant="h1" aria-label="Projects"></Typography>
      {projectData.map((project, index) => (
        <ProjectDisplay key={project.id} project={project} index={index} />
      ))}
    </Stack>
  );
};

export default Projects;
