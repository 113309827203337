import introData from "../data/introData";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

const Home = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "fit-content",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
  <Typography variant="h1" aria-label="Home">
 
</Typography>
      <Card
        key={"bio"}
        elevation={3}
        sx={{
          width: { xs: "95%", md: "75%" },
          marginTop: { xs: "2rem", md: "3rem" },
          marginBottom: { xs: "2rem", md: "3rem" },
        }}
      >
        <CardContent
          sx={{
            display: { xs: "block", md: "block" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              float: { xs: "left", md: "left" },
              width: { xs: "50vw", md: "25vw" },
              height: { xs: "50vw", md: "25vw" },
              marginRight: { xs: "20px", md: "20px" },
              marginBottom: { xs: "5px", md: "5px" },
            }}
            src={`${introData.images[0].src}?fit=crop&auto=format`}
            alt={introData.images[0].alt}
          ></Box>
          <Typography
          variant="h2"
            sx={{
              fontFamily: "Patrick Hand",
              color: "#1976d2",
              fontSize: { xs: "8vw", md: "4vw" },
            }}
          >
            {introData.title}
          </Typography>
          {introData.paragraphs.map((paragraphs) => (
            <Typography
              paragraph={true}
              key={paragraphs.id}
              variant="body1"
              color="text.secondary"
              sx={{ "&:last-child": { marginBlockEnd: 0 } }}
            >
              {paragraphs.content}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Home;
