const publicationData = [
  {
    id: "project-4",
    title:
      "A Computable Algorithm for Medication Optimization in Heart Failure With Reduced Ejection Fraction",
    journal: "Journal of the American College of Cardiology",
    image: {
      src: "/images/publication-4.png",
      alt: "Computable Algorithm diagram",
    },
    authors:
      "Michael P. Dorsch, Aaron Sifuentes, David J. Cordwin, Rachel Kuo, Brigid E. Rowell, Juan J. Arzac, Ken DeBacker, Jessica L. Guidi, Scott L. Hummel, and Todd M. Koelling",
    boldIndex: 6,
    link: "https://www.jacc.org/doi/abs/10.1016/j.jacadv.2023.100289",
  },
  {
    id: "project-3",
    title:
      "Consumer perceptions of the OTC Coach: A clinical decision support system aimed at improving the safe use of over-the-counter medications",
    journal: "Journal of the American Pharmacists Association",
    image: { src: "/images/publication-3.png", alt: "Over the Counter Coach diagram" },
    authors:
      "Sarah E. Vordenberg, Yehya Maitah, Zaid Khan, Ken DeBacker, Michael P. Dorsch",
    boldIndex: 3,
    link: "https://www.sciencedirect.com/science/article/pii/S1544319122003090",
  },
  {
    id: "project-2",
    title: "Quaternary-centre-guided synthesis of complex polycyclic terpenes",
    journal: "Nature",
    image: {
      src: "/images/publication-2.png",
      alt: "Chemical Synthesis of Conidiogenone B Diagram",
    },
    authors:
      "Pengfei Hu, Hyung Min Chi, Kenneth C. DeBacker, Xu Gong, Jonathan H. Keim, Ian Tingyung Hsu & Scott A. Snyder",
    boldIndex: 2,
    link: "https://www.nature.com/articles/s41586-019-1179-2",
  },
  {
    id: "project-1",
    title:
      "Synthesis of Enhanced, Isolable Disulfanium Salts and their Application to Thiiranium-Promoted Polyene Cyclizations",
    journal: "Synthesis",
    image: {
      src: "/images/publication-1.png",
      alt: " Salts Reaction Diagram",
    },
    authors:
      "Charles J. F. Cole, Hyung Min Chi, Kenneth C. DeBacker, Scott A. Snyder  ",
    boldIndex: 2,
    link: "https://www.thieme-connect.com/products/ejournals/abstract/10.1055/s-0037-1609754",
  },
];

export default publicationData;
