import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const NoPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "fit-content",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
        <Typography variant="h1" aria-label="Page not found"></Typography>
      <Box
        component="img"
        sx={{
          width: { xs: "35vw", md: "25vw" },
          height: "fit-content",
          marginTop: { xs: "15px", md: "25px" },
        }}
        src={`/images/404.png`}
        alt={"404 Page not found"}
        loading="lazy"
      ></Box>
      <Typography
      variant="h2"
        sx={{
          fontFamily: "Patrick Hand",
          color: "#1976d2",
          fontSize: { xs: "12vw", md: "7vw" },
        }}
      >
        {"Page Not Found"}
      </Typography>
    </Box>
  );
};

export default NoPage;
