import publicationData from "../data/publicationData";
import PublicationDisplay from "../components/PublicationDisplay";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";

const Publications = () => {
  return (
    <Stack
      sx={{
        marginTop: { xs: "2rem", md: "3rem" },
        marginBottom: { xs: "2rem", md: "3rem" },
      }}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
              <Typography variant="h1" aria-label="Publications"></Typography>
      {publicationData.map((publication, index) => (
        <PublicationDisplay
          key={publication.id}
          publication={publication}
          index={index}
        />
      ))}
    </Stack>
  );
};

export default Publications;
