// https://www.w3schools.com/react/react_router.asp
import ReactDOM from "react-dom/client";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavWrapper from "./Nav";
import Home from "./pages/Home";
import Publications from "./pages/Publications";
import Projects from "./pages/Projects";
import NoPage from "./pages/NoPage";
import { theme } from "./theme";
import { ThemeProvider } from "@emotion/react";
import "./index.css";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NavWrapper />}>
            <Route index element={<Home />} />
            <Route path="projects" element={<Projects />} />
            <Route path="publications" element={<Publications />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
