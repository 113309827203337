/* Code from https://mui.com/material-ui/react-app-bar/ */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwimmingFish from "./SwimmingFish";
import contactData from "../data/contactData";
import { getIcon } from "../data/iconMap";
import "../index.css";

function NavBar({ pages }) {
  const location = useLocation();

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElContact, setanchorElContact] = useState(null);

  const [value, setValue] = useState("/");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenContactMenu = (event) => {
    setanchorElContact(event.currentTarget);
  };

  const handleCloseContactMenu = () => {
    setanchorElContact(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="page navigation menu button"
              aria-controls="menu-navigation"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-navigation"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Link
                  key={page.toLowerCase()}
                  style={{ textDecoration: "none" }}
                  to={`/${page !== "Home" ? page.toLowerCase() : ""}`}
                >
                  <MenuItem
                    disableRipple={true}
                    sx={{
                      "&:hover": {
                        backgroundColor:
                          value === `/${page.toLowerCase()}` ||
                          (page === "Home" && value === "/")
                            ? "#1976d2"
                            : "white",
                      },
                      backgroundColor:
                        value === `/${page.toLowerCase()}` ||
                        (page === "Home" && value === "/")
                          ? "#1976d2"
                          : "white",
                    }}
                    onClick={handleCloseNavMenu}
                  >
                    <Button
                      disableRipple
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        color:
                          value === `/${page.toLowerCase()}` ||
                          (page === "Home" && value === "/")
                            ? "white"
                            : "#1976d2",
                      }}
                      startIcon={getIcon(
                        page.toLowerCase(),
                        "inherit",
                        25,
                        value === `/${page.toLowerCase()}` ||
                          (page === "Home" && value === "/")
                          ? "white"
                          : "#1976d2"
                      )}
                      size="medium"
                    >
                      {page}
                    </Button>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              mr: 1,
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              <SwimmingFish />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Tabs
              sx={{
                "& a": { color: "#FFFFFF" },
              }}
              TabIndicatorProps={{
                sx: {
                  cursor: "pointer",
                  background: "#FFFFFF",
                  height: "1.5px",
                  top: "85%",
                  borderRadius: "51px",
                },
              }}
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              onChange={handleChange}
              value={value}
            >
              {pages.map((page) => {
                return (
                  <Tab
                    sx={{ zIndex: 5, cursor: "pointer" }}
                    key={page.toLowerCase()}
                    value={`/${page !== "Home" ? page.toLowerCase() : ""}`}
                    label={page === "Home" ? "About Me" : page}
                    component={Link}
                    to={`/${page !== "Home" ? page.toLowerCase() : ""}`}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              ml: 2,
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SwimmingFish />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "flex" } }}>
            <Button
              size="medium"
              variant="contained"
              aria-label="contact information dropdown button"
              aria-controls="menu-contactInfo"
              aria-haspopup="true"
              onClick={handleOpenContactMenu}
              color="secondary"
            >
              More Info
            </Button>
            <Menu
              id="menu-contactInfo"
              anchorEl={anchorElContact}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElContact)}
              onClose={handleCloseContactMenu}
              sx={{
                display: { xs: "block", md: "block" },
              }}
            >
              {contactData.map((contact) => (
                <Link
                  key={contact.id}
                  style={{ textDecoration: "none" }}
                  to={contact.url}
                >
                  <MenuItem>
                    <Button
                      disableRipple
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      startIcon={getIcon(
                        contact.title.toLowerCase(),
                        "inherit",
                        25,
                        contact.iconColor
                      )}
                      size="medium"
                      onClick={() => {
                        window.location.href = contact.url;
                      }}
                    >
                      {contact.title}
                    </Button>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
