import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArticleIcon from "@mui/icons-material/Article";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WebIcon from "@mui/icons-material/Web";

const getIcon = (icon, color, size, customColor) => {
  if (icon === "project") {
    return (
      <WebIcon color={color} style={{ color: customColor, fontSize: size }} />
    );
  }
  if (icon === "publications") {
    return (
      <MenuBookIcon
        color={color}
        style={{ color: customColor, fontSize: size }}
      />
    );
  }
  if (icon === "projects") {
    return (
      <CodeIcon color={color} style={{ color: customColor, fontSize: size }} />
    );
  }
  if (icon === "home") {
    return (
      <HomeIcon color={color} style={{ color: customColor, fontSize: size }} />
    );
  }
  if (icon === "github") {
    return (
      <GitHubIcon
        color={color}
        style={{ color: customColor, fontSize: size }}
      />
    );
  }
  if (icon === "youtube") {
    return (
      <YouTubeIcon
        color={color}
        style={{ color: customColor, fontSize: size }}
      />
    );
  }
  if (icon === "linkedin") {
    return (
      <LinkedInIcon
        color={color}
        style={{ color: customColor, fontSize: size }}
      />
    );
  }
  if (icon === "email") {
    return (
      <MailOutlineIcon
        color={color}
        style={{ color: customColor, fontSize: size }}
      />
    );
  }
  if (icon === "resume") {
    return (
      <ArticleIcon
        color={color}
        style={{ color: customColor, fontSize: size }}
      />
    );
  }
  if (icon === "link") {
    return (
      <OpenInNewIcon
        color={color}
        style={{ color: customColor, fontSize: size }}
      />
    );
  }
};

export { getIcon };
