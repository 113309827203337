import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { CardHeader } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { getIcon } from "../data/iconMap";
import Divider from "@mui/material/Divider";
import ShakingButton from "./ShakingButton";
import ShakingIconButton from "./ShakingIconButton";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ProjectDisplay({ project, index }) {
  const desktopView = useMediaQuery("(min-width:900px)");

  return (
    <Card
      sx={{
        width: { xs: "95%", md: "75%" },
        boxShadow: 2,
      }}
    >
      <CardHeader
        title={
          <Typography
          variant="h2"
            sx={{
              fontFamily: "Patrick Hand",
              color: "#1976d2",
              fontSize: "2.5em",
            }}
          >
            {project.title}
          </Typography>
        }
        subheader={project.date}
      ></CardHeader>
      <CardMedia
        component="img"
        sx={{ objectFit: "contain", objectPosition: "top center" }}
        image={desktopView ? project.image.src : project.image.srcMobile}
        alt={project.image.alt}
        loading={index === 0 ? "eager" : "lazy"}
      />
      <CardContent>
        {project.descriptions.map((description) => (
          <Typography
            paragraph={true}
            key={description.id}
            variant="body1"
            color="text.secondary"
            sx={{ "&:last-child": { marginBlockEnd: 0 } }}
          >
            {description.content.map((paragraph, index) =>
              paragraph.length === 2 ? (
                <Link
                  sx={{
                    color: "#1976d2",
                    textDecoration: "none",
                    backgroundImage:
                      "linear-gradient(rgba(30,136,229,1), rgba(30,136,229,1))",
                    backgroundPosition: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "0% 1.5px",
                    transition: "background-size .25s ease-out",
                    "&:hover": {
                      md: {
                        backgroundPosition: "0% 100%",
                        backgroundSize: "100% 1.5px;",
                        transformOrigin: "bottom left",
                      },
                    },
                  }}
                  key={`${description.id}${index}`}
                  href={paragraph[1]}
                >
                  {paragraph[0]}
                </Link>
              ) : (
                <React.Fragment key={`${description.id}-${index}`}>
                  {paragraph[0]}
                </React.Fragment>
              )
            )}
          </Typography>
        ))}
      </CardContent>
      <CardActions>
        {project.linksNoIcon.map((link) => (
          <React.Fragment key={`${project.id}${1}`}>
            <ShakingButton
              keyVal={link.id}
              startIcon={getIcon("project", "primary", 35, null, true)}
              text={"View"}
              url={link.url}
            />
            {project.linksIcon.length > 0 ? (
              <Divider orientation="vertical" variant="middle" flexItem />
            ) : null}
          </React.Fragment>
        ))}
        {project.linksIcon.map((link, index) => (
          <React.Fragment key={`${project.id}${1 + index}`}>
            <ShakingIconButton
              icon={getIcon(link.iconName, "inherit", 35, link.iconColor)}
              label ={link.iconName}
              url={link.url}
              marginRight={
                index === 0 && project.linksNoIcon.length === 0 ? "8px" : "0px"
              }
              id={link.id}
            />
            {index < project.linksIcon.length - 1 ? (
              <Divider orientation="vertical" variant="middle" flexItem />
            ) : null}
          </React.Fragment>
        ))}
      </CardActions>
    </Card>
  );
}
