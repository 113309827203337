// https://www.w3schools.com/react/react_router.asp
import { Outlet } from "react-router-dom";
import NavBar from "./components/NavBar";

const pages = ["Home", "Projects", "Publications"];

const NavWrapper = () => {
  return (
    <div className={"app"}>
      <nav>
        <NavBar pages={pages} />
      </nav>
      <Outlet />
    </div>
  );
};

export default NavWrapper;
