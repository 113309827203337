import IconButton from "@mui/material/IconButton";

export default function ShakingIconButton({ url, icon, marginRight, label }) {
  const rotateAngle = 10;

  return (
    <IconButton
      onClick={() => {
        window.location.href = url;
      }}
      aria-label={label}
      sx={{
        marginRight: marginRight,
        "&:hover": {
          md: {
            "& svg": {
              "@keyframes shake": {
                "0%": {
                  transform: `rotate(0deg)`,
                },
                "16%": {
                  transform: `rotate(-${rotateAngle}deg)`,
                },
                "32%": {
                  transform: `rotate(0deg)`,
                },
                "48%": {
                  transform: `rotate(${rotateAngle}deg)`,
                },
                "64%": {
                  transform: `rotate(0deg)`,
                },
                "80%": {
                  transform: `rotate(-${rotateAngle}deg)`,
                },
                "100%": {
                  transform: `rotate(0deg)`,
                },
              },
              animation: `shake ease-in-out .5s`,
            },
          },
        },
      }}
    >
      {icon}
    </IconButton>
  );
}
